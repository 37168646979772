import moment from "moment";
import { mapGetters, mapState } from "vuex";
import bouncer from "@/helpers/bouncer";
import catalogService from "@/modules/core/service/catalog.service";
import employeeService from "@/modules/core/service/employee.service";
import { dateFormats } from "@/helpers/consts";

export default {
    mixins: [bouncer],
    computed: {
        ...mapState("auth", ["isAdmin"]),
        ...mapGetters("bread", ["breadcrumb"]),
        subsidiaries() {
            return this.selectedCountry ? this.selectedCountry.subsidiaries : [];
        }
    },
    data() {
        return {
            selectedCountry: null,
            selectSubsidiary: null,
            from: null,
            to: null,
            countries: [],
            loading: false,
            confirmDeleteModal: false,
            processing: false,
            pswDialog: false,
            submitted: false,
            filters: {},
            employeeList: null,
            currentEmployee: null,
            layout: "list",
            search: null,
            password: {},
            page: {
                page: 1,
                per_page: 9,
                total: 0,
                order: "desc"
            }
        };
    },
    carService: null,
    eventService: null,
    nodeService: null,
    created() {},
    mounted() {
        if (this.canAccess("empleados_read")) {
            const dispatchType = "bread/setItems";
            const listBreadcrumb = [
                { label: "Administración", url: "javascript:void(0);" },
                { label: "Lista de empleados", url: "javascript:void(0);" }
            ];
            this.$store.dispatch(dispatchType, listBreadcrumb);
            this.getData();
            const catalogServicePath = `select/countries/subsidiaries`;
            catalogService.get(catalogServicePath).then(response => {
                this.countries = response.data;
            });
        }
    },
    methods: {
        notification(severity, summary, detail) {
            this.$toast.add({
                severity: severity,
                summary: summary,
                detail: detail,
                life: this.$utils.toastLifeTime()
            });
        },
        reload() {
            this.search = "";
            this.to = null;
            this.from = null;
            this.selectedCountry = null;
            this.selectSubsidiary = null;
            this.filter();
        },
        onPage(event) {
            this.page.page = ++event.page;
            this.filter();
        },
        getData(params = {}) {
            this.loading = true;
            const config = {
                ...this.page,
                ...params
            };

            employeeService.getTb(config)
                .then(response => {
                    this.employeeList = response.data.data;
                    this.page.total = response.data.total;
                    this.loading = false;
                })
                .finally(() => (this.loading = false));
        },
        confirmDelete(employee) {
            this.currentEmployee = employee;
            this.confirmDeleteModal = true;
        },
        async edit(Employee) {
            const EmployeeRoute = "/employeecrud/";
            await this.$router.push(EmployeeRoute + Employee.id);
            await this.$route.params.pathMatch;
        },
        deleteRow() {
            this.processing = true;
            employeeService.delete(this.currentEmployee.id).then(() => {
                this.confirmDeleteModal = false;
                this.getData();
            });
        },
        filter() {
            this.loading = true;
            const q = this.search !== "" ? this.search : undefined;
            this.getData({
                q,
                ...{
                    to: this.to ? moment(this.to).format(dateFormats.YYYYMMDD) : null
                },
                ...{
                    from: this.from
                        ? moment(this.from).format(dateFormats.YYYYMMDD)
                        : null
                },
                ...{ country: this.selectedCountry ? this.selectedCountry.id : null },
                ...{
                    subsidiary: this.selectSubsidiary ? this.selectSubsidiary.id : null
                }
            });
        },
        savePassword() {
            this.submitted = true;
            if (
                !this.password.password ||
                this.password.password_confirmation === ""
            ) {
                return;
            }
            this.processing = true;
            this.formDialog = false;
            employeeService
                .changePassword(this.currentEmployee.id, this.password)
                .then(() => {
                    const summary = "Registro guardado";
                    const detail = "Se guardó correctamente";
                    this.notification("success", summary, detail);
                    this.pswDialog = false;
                })
                .catch(error => {
                    const message = error.response.data;
                    let errorDetails = "";
                    Object.keys(message).forEach(key => {
                        errorDetails +=
                            errorDetails + message[key] && [...message[key]].shift()
                                ? [message[key]].shift() + "\n"
                                : "";
                    });
                    const summary = "Error";
                    this.notification("error", summary, errorDetails);
                })
                .finally(() => {
                    this.submitted = false;
                    this.processing = false;
                });
        }
    }
};
